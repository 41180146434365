import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Modal from "../components/modal"
import Footer from "../components/footer"

import Yksi from "../images/1.jpg"
import Kaksi from "../images/2.jpg"
import Kolme from "../images/3.jpg"
import Nelja from "../images/4.jpg"
import Viisi from "../images/5.jpg"
import Kuusi from "../images/6.jpg"
import Seitseman from "../images/7.jpg"
import Kahdeksan from "../images/8.jpg"
import Yhdeksan from "../images/9.jpg"
import Kymmenen from "../images/10.jpg"
import Yksitoista from "../images/11.jpg"
import Kaksitoista from "../images/12.jpg"
import Kolmetoista from "../images/13.jpg"
import NeljaToista from "../images/14.jpg"
import Viisitoista from "../images/15.jpg"
import Kuusitoista from "../images/16.jpg"
import SeitsemanToista from "../images/17.jpg"
import Kahdeksantoista from "../images/18.jpg"
import Yhdeksantoista from "../images/19.jpg"
import KaksKyta from "../images/20.jpg"
import KaksKytaYks from "../images/21.jpg"

//import Portofolio from "../images/2020AISTO.pdf"

// row-cols-numero
// 3 on määrä jota on vierekkäin kuvia
const IndexPage = () => (
  <Layout title="Projektit">
       <SEO title="Projektit" />
    <div className="card-columns px-3 pb-5">
      <div className="card border-0 mb-0">
        <Modal image={Yksi} text="MNy Arkkitehdit, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Nelja} text="MNy Arkkitehdit, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Seitseman} text="MNy Arkkitehdit, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kymmenen} text="MNy Arkkitehdit, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kolmetoista} text="Naantalin Punakallio, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kuusitoista} text="MNy Arkkitehdit, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Yhdeksantoista} text="POAS, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kaksi} text="Arkkitehtitoimisto Helamaa & Heiskanen, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Viisi}  text="Arkkitehtitoimisto Helamaa & Heiskanen, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kahdeksan} text="Honkarakenne, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Yksitoista} text="Aisto, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={NeljaToista} text="Honkarakenne, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={SeitsemanToista} text="PopÅ, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={KaksKyta} text="The Barö, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kolme} text="The Barö, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kuusi} text="Honkatalot, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Yhdeksan} text="POAS, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kaksitoista} text="Pirkan Opiskelija-asunnot, 2019" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Viisitoista} text="Honkarakenne, 2020" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={Kahdeksantoista} text="The Barö, 2021" />
      </div>
      <div className="card border-0 mb-0">
        <Modal image={KaksKytaYks} text="Aisto, 2019" />
      </div>
    </div>
    <Footer></Footer>
  </Layout>
)

export default IndexPage
