import React, { Component } from "react";

export default class Modal extends Component {

  constructor(props) {
    super(props);
    this.state = {
        modal: false
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(){
    this.setState({ modal: !this.state.modal })
  }

  render() {

  return (
    <div className={`${this.state.modal ? 'modal-open' :'card-img'}`} style={{
      display: 'flex'
    }}>
        <img src={this.props.image} alt="tuotekuva" type="button" onClick={ this.toggleModal } 
        style={{
          objectFit: 'cover'
        }}/>
        <div 
            onClick={this.toggleModal}
            className={`modal fade bd-example-modal-xl ${this.state.modal ? 'show' : ''}`} 
            style={{
                display: `${this.state.modal ? 'block' : 'none'}`,
            }}
            id="imageModal" tabIndex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-xl" role="document"> 
                <div className="modal-content">
                  <img src={this.props.image} alt="suuriTuotekuva"/>
                  <p> {this.props.text} </p>
                </div>
              </div>
        </div>
    </div>

  );
  }
}